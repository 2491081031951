<script>
import confetti from 'canvas-confetti';

export default {
  props: ['timeout'],
  mounted () {
    confetti({
      particleCount: 150,
      spread: 180
    });
  }
};
</script>
